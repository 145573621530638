<template>
  <Masonry :value="value" class="custom-masonry" />
</template>

<script>
import Masonry from '@kvass/template-gallery/Types/Masonry'

export default {
  props: {
    data: Object,
  },

  computed: {
    value() {
      return this.$path('content', this.data) || []
    },
  },

  components: {
    Masonry,
  },
}
</script>

<style lang="scss">
.custom-masonry {
  .masonry__content {
    gap: 1rem;
  }
}
</style>
