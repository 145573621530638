var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"route",class:['route--' + _vm.view.toLowerCase(), _vm.isOnTop ? 'page--is-top' : 'page--is-scrolled'],style:(`--background-color: ${_vm.backgroundColor};--body-color: ${_vm.bodyColor};`),attrs:{"id":"app"}},[(_vm.$route.meta.navigation !== false)?_c('div',{staticClass:"nav",class:[
      'nav--' + _vm.view.toLowerCase(),
      {
        ['nav--' + _vm.view.toLowerCase() + '-expanded']: _vm.isExpanded,
        [`nav--${_vm.view.toLowerCase()}-${_vm.slug}`]: _vm.view.toLowerCase() == 'post',
      },
    ]},[_c('div',{staticClass:"container"},[_c('NavigationComponent',{attrs:{"logo":_vm.logo,"items":_vm.items,"title":_vm.projectName,"padding":"20px 0","action":_vm.mainAction || _vm.redirect(['Project', 'Residential'], { scrollTo: 0 }),"isOpen":_vm.isExpanded},on:{"update:isOpen":function($event){_vm.isExpanded=$event},"update:is-open":function($event){_vm.isExpanded=$event}}})],1)]):_vm._e(),_c('transition',{attrs:{"mode":"out-in","name":"route"}},[_c('router-view')],1),(_vm.$route.meta.footer !== false)?_c('FooterComponent',{attrs:{"navigation":_vm.items,"cookie-manager":_vm.CM}}):_vm._e(),_c('AssetComponent'),_c('Designer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }