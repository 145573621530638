import { GraphQLClient } from 'graphql-request'
import { getCookie } from '@/utils'
import Queries from './graphql/queries'
import Mutations from './graphql/mutations'

class API {
  constructor() {
    let params = new URLSearchParams(location.search)
    this.project = getCookie('kvass.project') || params.get('project-id')
    this.projectType = getCookie('kvass.projectType') || 'default'
  }

  get logo() {
    return `/api/project/${this.project}/logo`
  }

  get logoInverted() {
    return `/api/project/${this.project}/logo-inverted`
  }

  get favicon() {
    return `/api/project/${this.project}/favicon`
  }

  get projectId() {
    return this.project
  }

  request(...args) {
    return new GraphQLClient('/api/graphql', {
      headers: {
        'accept-language': document.querySelector('html').getAttribute('lang'),
      },
    }).request(...args)
  }

  getProject(id) {
    if (!id) id = this.project

    let getQuery = () => {
      switch (this.projectType) {
        case 'singular':
          return Queries.ProjectResidential
        default:
          return Queries.Project
      }
    }

    return this.request(getQuery(), { id }).then(data => data.Project)
  }

  getPosts(id) {
    if (!id) id = this.project
    return this.request(Queries.Posts, { id }).then(data => data.Posts)
  }

  getResidential(id) {
    return this.request(Queries.Residential, { id }).then(data => data.Residential)
  }

  getRoot(id) {
    if (!id) id = this.project

    return this.request(Queries.Root, { id }).then(data => data.Project)
  }

  createLead(data) {
    return this.request(Mutations.LeadCreate, { data }).then(data => data.LeadCreate)
  }

  getFlatfinder(id) {
    return this.request(Queries.Flatfinder, { id }).then(data => data.Flatfinder)
  }
}

export default new API()
