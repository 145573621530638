export default function(t, e, options = {}) {
  let { variable = 'primary' } = options
  var r, o, n

  function getContrastLightness(hexcolor) {
    hexcolor = hexcolor.replace('#', '')
    var r = parseInt(hexcolor.substr(0, 2), 16)
    var g = parseInt(hexcolor.substr(2, 2), 16)
    var b = parseInt(hexcolor.substr(4, 2), 16)
    var yiq = (r * 299 + g * 587 + b * 114) / 1000

    return yiq >= 140 ? 20 : 100
  }

  function HSLToHex(h, s, l) {
    s /= 100
    l /= 100

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0

    if (0 <= h && h < 60) {
      r = c
      g = x
      b = 0
    } else if (60 <= h && h < 120) {
      r = x
      g = c
      b = 0
    } else if (120 <= h && h < 180) {
      r = 0
      g = c
      b = x
    } else if (180 <= h && h < 240) {
      r = 0
      g = x
      b = c
    } else if (240 <= h && h < 300) {
      r = x
      g = 0
      b = c
    } else if (300 <= h && h < 360) {
      r = c
      g = 0
      b = x
    }
    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16)
    g = Math.round((g + m) * 255).toString(16)
    b = Math.round((b + m) * 255).toString(16)

    // Prepend 0s, if necessary
    if (r.length == 1) r = '0' + r
    if (g.length == 1) g = '0' + g
    if (b.length == 1) b = '0' + b

    return '#' + r + g + b
  }

  function hexToHSL(H) {
    // Convert hex to RGB first
    if (!H) return
    let r = 0,
      g = 0,
      b = 0
    if (H.length == 4) {
      r = '0x' + H[1] + H[1]
      g = '0x' + H[2] + H[2]
      b = '0x' + H[3] + H[3]
    } else if (H.length == 7) {
      r = '0x' + H[1] + H[2]
      g = '0x' + H[3] + H[4]
      b = '0x' + H[5] + H[6]
    }
    // Then to HSL
    r /= 255
    g /= 255
    b /= 255
    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0

    if (delta == 0) h = 0
    else if (cmax == r) h = ((g - b) / delta) % 6
    else if (cmax == g) h = (b - r) / delta + 2
    else h = (r - g) / delta + 4

    h = Math.round(h * 60)

    if (h < 0) h += 360

    l = (cmax + cmin) / 2
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
    s = +(s * 100).toFixed(1)
    l = +(l * 100).toFixed(1)

    return {
      h,
      s,
      l,
    }
  }
  function moveUp(t) {
    var parent = t.parentNode
    parent.insertBefore(t, t.previousSibling)
  }
  function moveDown(t) {
    var parent = t.parentNode
    parent.insertBefore(t.nextSibling, t)
  }

  switch (t) {
    case 'font':
      return (
        (r = e),
        void (WebFont.load({ google: { families: [r] } }),
        document.querySelector(':root').style.setProperty(`--${variable}-font`, r))
      )
    case 'color':
      let i, y, p
      return (
        (i = hexToHSL((o = e))),
        (p = hexToHSL(
          (y =
            ((n = o).startsWith('#') && (n = n.substring(1)),
            '#' +
              (16777215 ^ Number(`0x1${n}`))
                .toString(16)
                .substring(1)
                .toUpperCase())),
        )),
        void (document.querySelector(':root').style.setProperty('--primary', o, 'important'),
        document.querySelector(':root').style.setProperty('--primary-h', i.h, 'important'),
        document.querySelector(':root').style.setProperty('--primary-s', i.s, 'important'),
        document.querySelector(':root').style.setProperty('--primary-l', i.l, 'important'),
        document
          .querySelector(':root')
          .style.setProperty(
            '--primary-contrast',
            HSLToHex(i.h, i.s, getContrastLightness(e)),
            'important',
          ),
        document.querySelector(':root').style.setProperty('--secondary', y, 'important'),
        document.querySelector(':root').style.setProperty('--secondary-h', p.h, 'important'),
        document.querySelector(':root').style.setProperty('--secondary-s', p.s, 'important'),
        document.querySelector(':root').style.setProperty('--secondary-l', p.l, 'important'),
        document
          .querySelector(':root')
          .style.setProperty(
            '--secondary-contrast',
            HSLToHex(p.h, p.s, getContrastLightness(y)),
            'important',
          ))
      )
    case 'sort':
      return (function t(e) {
        let r = document.querySelectorAll(e)

        r.forEach(element => {
          let btnUp = document.createElement('button')
          btnUp.addEventListener('click', () => moveUp(element))
          btnUp.innerHTML = 'Flytt opp'
          btnUp.classList.add('designer__sort-button', 'designer__sort-button--up')

          let btnDown = document.createElement('button')
          btnDown.addEventListener('click', () => moveDown(element))
          btnDown.innerHTML = 'Flytt ned'
          btnDown.classList.add('designer__sort-button', 'designer__sort-button--down')

          element.appendChild(btnUp)
          element.appendChild(btnDown)
        })
      })(e)
  }
}
