<template>
  <div v-if="showContent" class="designer" :class="{ 'designer--open': show }">
    <div @click="() => (show = !show)" class="designer__trigger">
      <FontAwesomeIcon
        class="designer__trigger-icon"
        :icon="['fal', `angle-${show ? 'down' : 'up'}`]"
        size="lg"
      />
      {{ show ? 'Skjul' : 'Rediger design' }}
    </div>

    <div v-if="show" class="designer__content">
      <InputComponent
        class="designer__predefined"
        :label="labels['predefined']"
        :icon="['fal', 'paint-roller']"
      >
        <select class="elder-input__element" v-model="predefined" @change="addPredefined">
          <option :value="null" disabled dir="ltr">Velg</option>
          <option v-for="item in predefinedOptions" :key="item.value" :value="item">
            {{ item.label }}
          </option>
        </select>
      </InputComponent>
      <div class="designer__colors">
        <InputComponent :label="labels['color']" :icon="['fal', 'palette']">
          <template #default>
            <input
              type="color"
              class="elder-input__element"
              :value="color"
              @input="$ev => designerController('color', $ev.target.value)"
              :style="{ height: `50px` }"
            />
          </template>
        </InputComponent>
      </div>
      <!-- 
      <InputComponent :label="labels['sort']" class="designer__sort">
        <ButtonComponent
          :label="sortActive ? 'Deaktiver' : 'Aktiver'"
          :icon="['fal', 'sort']"
          icon-placement="left"
          @click="sort"
        />
      </InputComponent> -->

      <div class="designer__fonts">
        <InputComponent :label="labels['font.primary']" :icon="['fal', 'font']">
          <select
            class="elder-input__element"
            v-model="fonts.primary"
            @change="() => designerController('font', fonts.primary, { variable: 'primary' })"
          >
            <option :value="null" disabled dir="ltr">Velg</option>
            <option v-for="item in fontOptions" :key="item.value" :value="item">
              {{ item }}
            </option>
          </select>
        </InputComponent>
        <InputComponent :label="labels['font.secondary']" :icon="['fal', 'font']">
          <select
            class="elder-input__element"
            v-model="fonts.secondary"
            @change="() => designerController('font', fonts.secondary, { variable: 'secondary' })"
          >
            <option :value="null" disabled dir="ltr">Velg</option>
            <option v-for="item in fontOptions" :key="item.value" :value="item">
              {{ item }}
            </option>
          </select>
        </InputComponent>
      </div>
    </div>
  </div>
</template>

<script>
const predefinedOptions = [
  {
    label: 'Sort',
    fonts: {
      primary: 'Nobile',
      secondary: 'Merienda',
    },
    color: '#212121',
  },
  {
    label: 'Jord',

    fonts: {
      primary: 'Open Sans',
      secondary: 'DM Serif Display',
    },
    color: '#473939',
  },
  {
    label: 'Grå',
    fonts: {
      primary: 'Montserrat',
      secondary: 'Stardos Stencil',
    },
    color: '#838387',
  },
  {
    label: 'Mørk blå',
    fonts: {
      primary: 'Cardo',
      secondary: 'Kalam',
    },
    color: '#2F323C',
  },
  {
    label: 'Lilla',
    fonts: {
      primary: 'Montserrat',
      secondary: 'Montserrat Alternates',
    },
    color: '#8A7C8D',
  },
]
import designerController from '@/design-controller'
import { InputComponent } from 'vue-elder-input'

export default {
  data() {
    return {
      predefinedOptions,
      color: '',
      fontOptions: [
        'Montserrat',
        'Rajdhani',
        'Cinzel',
        'DM Serif Display',
        'Open Sans',
        'Stardos Stencil',
        'Montserrat Alternates',
        'Kalam',
        'Merienda',
        'Cardo',
      ],
      fonts: {
        primary: null,
        secondary: null,
      },
      show: false,
      predefined: null,
      sortActive: false,
    }
  },
  computed: {
    labels() {
      return {
        color: 'Velg farge',
        'font.primary': 'Hoved font',
        'font.secondary': 'Tittel font',
        predefined: 'Predefinert styling',
        sort: 'Rekkefølge endring',
      }
    },

    showContent() {
      let hasParmas = this.$route.query['designer']
      if (hasParmas) this.show = true
      return hasParmas
    },
  },
  methods: {
    designerController,
    getCurrentFonts() {
      Object.keys(this.fonts).forEach(element => {
        this.fonts[element] = (
          getComputedStyle(document.documentElement).getPropertyValue(`--${element}-font`) || ''
        )
          .split(',')[0]
          .trim()
          .replace(/^"(.*)"$/, '$1')

        if (!this.fontOptions.includes(this.fonts[element]))
          this.fontOptions.push(this.fonts[element])
      })
    },
    sort() {
      this.sortActive = !this.sortActive
      if (this.sortActive) return designerController('sort', '.kpb-section')
      let buttons = document.querySelectorAll('.designer__sort-button')
      buttons.forEach(element => {
        element.remove()
      })
    },
    addPredefined() {
      this.fonts = this.predefined.fonts
      this.color = this.predefined.color
      designerController('color', this.predefined.color)
      designerController('font', this.predefined.fonts.secondary, { variable: 'secondary' })
      designerController('font', this.predefined.fonts.primary, { variable: 'primary' })
    },
  },
  mounted() {
    this.getCurrentFonts()
  },
  components: {
    InputComponent,
  },
}
</script>

<style lang="scss">
.designer {
  font-size: 1em;
  @include respond-below('tablet') {
    display: none;

    &__sort-button {
      display: none;
    }
  }
  box-shadow: 0 10px 30px -10px black;
  background-color: $grey;

  padding: 1rem 2rem;
  font-family: 'Arial';

  position: fixed;
  z-index: 1001;
  bottom: 0;
  right: 0;

  .elder-input {
    &__element {
      padding: 0.5em;
    }
    &__label {
      font-weight: initial;
    }
  }

  &--open {
    padding: 1rem 2rem 2rem 2rem;
    width: 500px;
    .designer__trigger {
      text-align: right;
    }
  }

  &__trigger {
    cursor: pointer;
    &-icon {
      margin-right: 0.3em;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    gap: 1.2rem;
  }

  &__colors,
  &__predefined {
    grid-column-end: span 2;
  }
  &__fonts {
    display: flex;
    gap: 1rem;
    grid-column-end: span 2;
    & > * {
      flex-grow: 1;
    }
  }
  &__sort {
    margin-top: auto;

    .elder-input__field {
      border: none;
    }
    .elder-button {
      border: 1px solid $grey;
      &__label {
        font-size: 1em;
        text-align: left;
        padding: 0 0.7em;
      }
    }

    &-button {
      margin: 1rem 0;
      padding: 0.3em;
      background: $grey;
      font-family: 'Arial';
      margin-left: 1rem;
      border: 1px solid rgb(84, 84, 84);
      border-radius: 3px;
    }
  }
}
</style>
