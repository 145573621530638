<template>
  <ImageText class="additional-sections" v-if="value.length" :value="value" theme="sections" />
</template>

<script>
import ImageText from '@kvass/template-image-text'

export default {
  props: {
    data: Object,
  },
  computed: {
    value() {
      return this.$path('content', this.data) || []
    },
  },
  components: {
    ImageText,
  },
}
</script>

<style lang="scss">
.additional-sections {
  .kvass-image-text__item {
    padding: 6rem;

    @include respond-below('tablet') {
      padding: 2rem;
    }
  }
  .kvass-media-render-image__caption {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 0.8em 2rem;
  }
}
</style>
