<template>
  <Loader :value="promises.fetch" theme="default" class="project-residential-page">
    <Blocks :blocks="config" :custom-components="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import ResidentialComponents from '@/components/Residential'
import CustomComponents from '@/components/Custom'

import { animate, inView } from 'motion'
import BrowserApiMixin from '@/mixins/browser-api'

// blocks
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/project-residential'

const PrefixKeys = (prefix, obj) =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, ...{ [prefix + key]: obj[key] } }), {})

export default {
  mixins: [BrowserApiMixin('project-residential')],
  props: {
    id: String,
  },
  watch: {
    id: {
      handler(val) {
        return this.fetch(val).then(data => {
          if (!data) return this.$router.push({ name: 'notfound' })
        })
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('Project', {
      item: 'item',
      promises: 'promises',
      residential: state => (state.item.residentials || [])[0] || {},
    }),
    config() {
      return config.call(this)
    },
    customComponents() {
      return {
        ...CustomComponents,
        ...ResidentialComponents,
        ...ProjectComponents,
      }
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
    initAnimation() {
      inView(
        '.section-header',
        ({ target }) => {
          animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
        },
        { margin: '0px 0px -200px 0px' },
      )

      inView('.kvass-image-text__item', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
    },
  },
  mounted() {
    this.promises.fetch.then(() => this.$nextTick(() => this.initAnimation()))
  },
  metaInfo() {
    return {
      title: this.item.name || `${this.$t('loading', { resource: null })}...`,
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.project-residential-page {
  min-height: 100vh;

  & > .kpb-blocks {
    @for $i from 1 through 15 {
      & > *:nth-child(#{$i}) {
        order: $i * 10;
      }
    }
  }

  .kvass-image-text__item {
    opacity: 0;
  }

  .section-floorplan {
    .kpb-gallery__item-caption {
      color: white;
    }
  }
  .residential__floorplan {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;

    .kpb-gallery__item:nth-child(1) {
      grid-column-end: span 3;
    }
  }

  .section-residentials {
    .kpb-section__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }
}
</style>
