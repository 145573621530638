<template>
  <div class="phases">
    <div class="phases__content" v-if="content" v-html="content"></div>
    <ul class="phases__items">
      <li
        v-for="(item, index) in value"
        :key="index"
        class="phases__item"
        :class="{
          'phases__item--active': item.current,
          'phases__item--check': check(index),
        }"
      >
        <span class="phases__item-content">{{ item.label }}</span>
        <strong class="phases__item-status">
          {{ item.content }}
        </strong>
      </li>
    </ul>
  </div>
</template>

<script>
const keys = ['planning', 'salestart', 'developmentstart', 'movein']

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: String,
    },
  },
  computed: {
    value() {
      let phase = this.data
      return keys.map(key => {
        return {
          label: this.$t(key),
          content: phase[key],
          current: phase['value'] === key,
        }
      })
    },
  },

  methods: {
    check(index) {
      if (index === 0) return true
      return this.value.indexOf(this.value.find(i => i.current)) > index
    },
  },
}
</script>

<style lang="scss">
.phases {
  $lineHeight: 1.3em;
  $dotSize: 1.5rem;
  $lineTickness: 4px;
  $resposivePadding: 2rem;

  display: flex;
  flex-direction: column;

  gap: 0.5rem;
  margin: 0 auto;

  &__title {
    font-weight: 400;
    text-align: center;
    font-size: 2.5rem;

    @include respond-below('phone') {
      font-size: 1.5rem;
    }
  }

  &__content {
    @include respond-above('phone') {
      margin-bottom: 1rem;
    }
  }

  &__items {
    display: flex;
    min-height: 120px;
    justify-content: center;
    padding: 0;
    margin: 0;

    @include respond-below('phone') {
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0;

    @include respond-below('phone') {
      padding: $resposivePadding;
      align-items: flex-start;
      background-color: $light-grey;
    }

    // horizontal line
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: $lineTickness;
      top: 0;
      right: 0;
      background-color: css-darken('grey', 20%);

      @include respond-below('phone') {
        right: 0%;
        left: 0;
        height: 100%;
        width: $lineTickness;
      }
    }

    &:after {
      content: '';
      width: $dotSize;
      aspect-ratio: 1/1;
      background-color: css-darken('grey', 20%);
      border-radius: 30rem;
      position: absolute;
      top: $dotSize / -3;
      z-index: 10;
      @include respond-below('phone') {
        top: calc(50% - #{$resposivePadding / 2});
        left: $lineTickness / 2;
        transform: translateX(-50%);
      }
    }

    &-name {
      font-weight: 300;
      font-size: 1.2rem;
      color: var(--primary);
      margin: 0;

      @include respond-below('phone') {
        font-size: 0.7rem;
        letter-spacing: 0.5px;
      }
    }
    &--active {
      &:after {
        @keyframes breathe {
          0% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          50% {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
          }
          to {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }

        @include respond-above('phone') {
          -webkit-animation: breathe 3s infinite ease-in-out;
          animation: breathe 3s infinite ease-in-out;
        }
      }
    }

    &--active,
    &--check {
      @include respond-below('phone') {
        background-color: css-alpha('primary', 15%);
      }

      &:after,
      &:before {
        background-color: var(--primary);
      }
    }
  }
}
</style>
