<template>
  <div
    :style="`--background-color: ${backgroundColor};--body-color: ${bodyColor};`"
    id="app"
    class="route"
    :class="['route--' + view.toLowerCase(), isOnTop ? 'page--is-top' : 'page--is-scrolled']"
  >
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        'nav--' + view.toLowerCase(),
        {
          ['nav--' + view.toLowerCase() + '-expanded']: isExpanded,
          [`nav--${view.toLowerCase()}-${slug}`]: view.toLowerCase() == 'post',
        },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="20px 0"
          :action="mainAction || redirect(['Project', 'Residential'], { scrollTo: 0 })"
          :isOpen.sync="isExpanded"
        />
      </div>
    </div>
    <transition mode="out-in" name="route">
      <router-view />
    </transition>

    <FooterComponent v-if="$route.meta.footer !== false" :navigation="items" :cookie-manager="CM" />
    <AssetComponent />
    <Designer />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import { mapState, mapActions } from 'vuex'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import Designer from '@/components/Designer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import { Limit } from '@/filters'
import { DarkTheme } from '@/utils'
import loadCM from '@/gdpr'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM: {},
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    slug() {
      return this.$route.params.slug
    },
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      // if (this.$route.name === 'Project' && !this.isSingular)
      //   return this.isOnTop ? this.logos.dark : this.logos.light
      return this.logos.light
    },
    isDemo() {
      return this.item.demo || false
    },
    posts() {
      return this.item.posts || []
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    backgroundColor() {
      return this.$path('customFields.background-color', this.item) || '#FFFFFF'
    },
    bodyColor() {
      if (DarkTheme(this.backgroundColor)) return '#FFFFFF'
    },
    projectName() {
      return this.item.name || ''
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },
    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },
    mainAction() {
      if (this.addons.includes('project:flatfinder')) return this.redirectUrl
      if (this.isSingular) return
      return this.redirect('Project', { scrollTo: 0 })
    },

    newsPage() {
      const news = this.posts.find(post => post.customFieldsConfig[0].source === 'news')
      if (!news) return {}

      return {
        id: news.title,
        label: news.title,
        exact: true,
        action: this.redirect(['Post'], { params: { slug: news.slug }, forceRedirect: true }),
      }
    },

    defaultItems() {
      return [
        {
          id: 'home',
          label: this.$t('home'),
          action: this.mainAction,
          condition: this.mainAction,
          order: 1,
        },
        ...this.posts.map(i => {
          if (!this.$path('customFields.page-settings.display-in-menu', i)) return {}
          return {
            label: i.title,
            exact: true,
            action: this.redirect(['Post'], { params: { slug: i.slug }, forceRedirect: true }),
            order: this.$path('customFields.page-settings.navigation-order', i) || 1,
            class: 'navigation-page',
            id: 'navigation-page',
          }
        }),
        {
          id: 'flatfinder',
          label:
            this.$path('customFields.title-overrides.flatfinder', this.item) ||
            KvassConfig.get('customLabels.flatfinder') ||
            this.$tc('flatfinder'),
          exact: true,
          action: this.redirect(['Project', 'Residential'], { hash: '#flatfinder' }),
          condition: this.hasFlatfinder && this.hasStatsTotal,
        },
        {
          id: 'gallery',
          label: this.$t('gallery'),
          action: this.redirect(['Gallery'], {}),
          condition: this.gallery.length,
        },

        {
          id: 'map',
          label: this.$t('map'),
          action: this.redirect(['Project'], {
            hash: '#map',
          }),
        },
        {
          id: 'brochure',
          label: this.$t('brochure'),
          exact: true,
          action: () => {
            if (this.brochure.length > 1) return
            AssetBus.emit('download', {
              type: 'brochure',
              strategy: this.brochureStrategy,
              asset: this.brochure[0],
            })
          },
          items:
            this.brochure.length > 1
              ? this.brochure.map(b => ({
                  label: Limit(b.name, 30),
                  action: () => {
                    AssetBus.emit('download', {
                      type: 'brochure',
                      strategy: this.brochureStrategy,
                      asset: b,
                    })
                  },
                  icon: ['fad', 'file-pdf'],
                }))
              : undefined,
          condition: this.brochure.length,
        },
        {
          id: 'attachment',
          label: this.$tc('attachment', 2),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#attachments',
          }),
          condition: this.hasAttachments,
        },

        {
          id: 'lead',
          label: this.$t('leadFormTitle'),
          class: 'elder__navigation-component--primary',
          action: this.redirect(['Project', 'Residential', 'Post'], {
            hash: '#lead',
          }),
          order: 10000,
        },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
        .map((i, index) => ({ ...i, order: i.order ? i.order : (index + 1) * 10 }))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }

    const storeScroll = () => {
      this.isOnTop = window.scrollY <= 5
    }

    document.addEventListener('scroll', debounce(storeScroll), { passive: true })
    storeScroll()

    this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
    Designer,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

#app {
  background-color: var(--background-color);
  color: var(--body-color);
}

.nav {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  background: var(--primary);
  box-shadow: 0 10px 15px -10px rgba(black, 0.05);

  transition: background 150ms ease-in;

  .elder-dropdown__content-box {
    .elder__navigation-component {
      color: black !important;
    }
  }

  .elder__navigation {
    &-bars {
      color: var(--primary-contrast);
    }

    &-component {
      color: var(--primary-contrast);
      font-weight: 300;
      padding: 8px 15px;
    }
  }

  .route--project.page--is-top & {
    .elder__navigation {
      &-bars {
        color: white;
      }
      &-component {
        color: white;
      }
    }
  }

  .container {
    max-width: initial;
  }

  @include respond-below('phone') {
    .elder__navigation-logo-image {
      transition: none;
      max-height: 3rem !important;
    }
  }

  @include respond-above('phone') {
    .route--project.page--is-top & {
      .elder__navigation-logo-image {
        transition: all 200ms ease;
      }
    }
  }

  .route--project.page--is-scrolled & {
    position: fixed;
    background: var(--primary);
    color: $dark;
    box-shadow: 0 10px 15px -10px rgba(black, 0.05);
  }

  .elder__navigation-component {
    &.elder__navigation-component--primary {
      background-color: var(--primary) !important;
      color: var(--primary-contrast) !important;
    }
  }

  &--project {
    position: absolute;
    background: linear-gradient(to bottom, black -200%, transparent 100%);
    box-shadow: none;

    &-expanded {
      box-shadow: none;
      background: linear-gradient(180deg, #000 -200%, #00000036);
      backdrop-filter: blur(5px);
      color: white;
      padding-bottom: 2rem;
    }
  }
}
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 500ms ease;
}
</style>
