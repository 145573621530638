<template>
  <div class="project-hero">
    <div class="project-hero__slide">
      <Slider v-if="items.length" :value="items" manualNavigation display-thumbnail />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ScrollIntoView } from '@/utils'
import Slider from '@kvass/media-render/Slider'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },
  },
  methods: {},
  metaInfo() {
    return {
      link: [...this.items.map(item => ({ rel: 'preload', as: 'image', href: item }))],
    }
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.project-hero {
  $height: 100vh;

  position: relative;
  height: $height;
  width: 100%;
  background-color: css-alpha('primary', 0.1);

  display: flex;
  flex-direction: column;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 500ms;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  &__slide {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    iframe {
      min-height: $height;
    }
  }
}
</style>
